<template>
  <div class="app-line-chart">
    <chartist
      ratio="ct-major-tenth"
      type="Line"
      :data="chartData"
      :options="{...defaultOptions, ...chartOptions}"
      :responsiveOptions="responsiveOptions">
    </chartist>
  </div>
</template>

<script>
import Vue from 'vue'
import VueChartist from 'vue-chartist'

Vue.use(VueChartist)

export default {
  name: 'AppLineChart',

  props: {
    chartData: {
      type: Object,
      required: true
    },

    chartOptions: {
      type: Object,
      required: false
    }
  },

  data () {
    return {
      defaultOptions: {
        lineSmooth: true,
        fullWidth: true,
        showPoint: false,
        showArea: true,
        axisX: {
          showLabel: false,
          offset: 0,
          showGrid: false,
          labelInterpolationFnc: (value, index) => {
            if (this.chartData.labels.length > 40) {
              return index % 5 === 0 ? value : null
            } else {
              return value
            }
          }
        },
        axisY: {
          showLabel: false,
          offset: 0
        },

        chartPadding: {
          left: 0,
          right: 0
        }
      },

      responsiveOptions: [
        ['screen and (min-width: 768px)', {
          axisX: {
            showLabel: true,
            offset: 30
          },

          axisY: {
            showLabel: true,
            offset: 30
          }
        }]
      ]
    }
  }
}
</script>

<style scoped>
  @import url('https://cdn.jsdelivr.net/chartist.js/latest/chartist.min.css');

  .app-line-chart {
    margin-bottom: 50px;
  }

  @media screen and (min-width: 768px) {
    .app-line-chart {
      margin-bottom: 80px;
    }
  }
</style>
