<template>
  <transition name="fade">
    <div @click.self="close()" class="modal fade show" tabindex="-1">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content shadow">
          <div class="modal-header">
            <!-- <h5 class="modal-title">{{ dish.name }}</h5> -->

            <button @click.prevent="close()" type="button" class="close" aria-label="Stäng">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div v-if="loaded" class="modal-body">
            <app-dish-card :dish="dish" :showButtons="false"></app-dish-card>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppDishCard from '@/components/AppDishCard'
import axios from 'axios'

export default {
  name: 'AppBsModal',

  props: {
    title: {
      type: String,
      required: false
    },

    dishId: {
      type: Number,
      required: true
    }
  },

  components: {
    AppDishCard
  },

  data () {
    return {
      dish: null,
      loaded: false
    }
  },

  watch: {
    show (value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }

      document.querySelector('body').classList.remove('overflow-hidden')
    }
  },

  methods: {
    confirm () {
      this.$emit('confirm')
    },

    close () {
      this.$emit('close')
    }
  },

  mounted () {
    axios.get(process.env.VUE_APP_API_URI + '/dishes/' + this.dishId)
      .then(response => {
        this.dish = response.data.data

        this.loaded = true
      })
      .catch(() => {})
  }
}
</script>

<style scoped>
  .modal {
    background: rgba(0, 0, 0, .5);
  }

  .modal.show {
    display: block;
  }

  .modal .modal-header {
    border-bottom: none;
    padding-bottom: .5rem;
  }

  .modal .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal .modal-content .modal-header {
    border-radius: 0;
  }
</style>
