<template>
  <button @click="filterCheck" class="btn btn-sm filter-button mr-2 mb-2 border"><font-awesome-icon :icon="checked ? ['fa', 'check-square'] : ['far', 'square']" class="filter-button-icon" style="font-size: 20px;" /><span class="ml-2 align-text-bottom">{{ title }}</span></button>
</template>

<script>
export default {
  name: 'AppFilterButton',

  props: {
    title: {
      type: String,
      required: true
    },
    filterValue: {
      type: Number,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {}
  },

  methods: {
    filterCheck () {
      this.$emit('filterChanged', this.filterValue)
    }
  }

  // watch: {
  //   checked (oldVal, newVal) {
  //     this.$emit('filterChanged', {
  //       catId: this.filterValue,
  //       catTitle: this.title,
  //       checked: newVal
  //     })
  //   }
  // },

  // mounted () {
  //   if (this.initChecked) {
  //     this.checked = true
  //   }
  // }
}
</script>

<style>
</style>
