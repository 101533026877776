<template>
  <div class="card mb-3" style="max-width: 720px;">
    <div class="row no-gutters flex-nowrap">
      <div :class="{ 'hide-sm': showComment }">
        <div v-if="dish.url">
          <a :href="dish.url" target="_none">
            <img v-if="dish.image_url" class="card-img" :src="dish.image_url" :alt="dish.name" onerror="this.onerror=null; this.src='/img/placeholder.png'">
            <img v-else class="card-img" src="/img/placeholder.png" :alt="dish.name">
          </a>
        </div>

        <div v-else>
          <img v-if="dish.image_url" class="card-img" :src="dish.image_url" :alt="dish.name">
          <img v-else class="card-img" src="/img/placeholder.png" :alt="dish.name">
        </div>
      </div>

      <div class="card-body d-flex justify-content-between flex-column">
        <div class="mb-3">
          <h4 class="card-title mb-0">
            <a v-if="dish.url" :href="dish.url" class="text-dark" target="_none">{{ dish.name }} <font-awesome-icon v-if="!showComment" icon="external-link-alt" class="text-muted" style="width: 10px; height: 10px; vertical-align: super;" /></a>

            <template v-else class="text-secondary">{{ dish.name }}</template>
          </h4>

          <div class="mt-1 mb-3 text-sm">{{ categoriesString }}</div>

          <div v-if="dish.comment" class="mb-4">
            <button class="d-flex align-items-center bg-transparent border-0 p-0" @click="showComment = !showComment">Visa kommentar <font-awesome-icon v-if="!showComment" icon="chevron-down" class="ml-2" /><font-awesome-icon v-if="showComment" icon="chevron-up" class="ml-2" /></button>

            <div v-if="showComment" class="card mt-3 comment-card">
              <div v-html="dish.comment" class="card-body"></div>
            </div>
          </div>
        </div>

        <div v-if="showButtons">
          <router-link :to="{ name: 'dishes.edit', params: { id: dish.id } }" class="btn btn-info btn-sm mr-3"><font-awesome-icon icon="edit" /> Ändra</router-link>

          <button @click="deleteDish(dish.id)" class="btn btn-danger btn-sm"><font-awesome-icon icon="trash-alt" /> Ta bort</button>
        </div>
      </div>

      <!-- <a v-if="dish.url" :href="dish.url" class="d-flex p-2">
        <font-awesome-icon icon="external-link-alt" class="text-secondary w-1 h-1" />
      </a> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AppDishCard',

  data () {
    return {
      showComment: false
    }
  },

  props: {
    dish: {
      type: Object,
      required: true
    },

    showButtons: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  computed: {
    categoriesString () {
      return this.dish.categories.map(cat => cat.name).join(' | ')
    }
  },

  methods: {
    deleteDish (id) {
      if (!confirm('Är du säker?')) {
        return
      }

      axios.delete(process.env.VUE_APP_API_URI + '/dishes/' + id, {})
        .then(() => {
          this.$emit('deletedDish', id)
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
  /* .row {
    flex-wrap: nowrap;
  } */

  .card-img {
    min-height: 150px;
    max-height: 250px;
    height: 100%;
    width: 150px;
    object-fit: cover;
  }

  @media screen and (max-width: 720px) {
    .card-img {
      width: 100px;
    }

    .card-title {
      font-size: 1.2rem;
    }

    .hide-sm {
      display: none;
    }

    .comment-card {
      border: none;
      border-top: 1px solid rgba(0,0,0,0.125);
      border-bottom: 1px solid rgba(0,0,0,0.125);
    }

    .comment-card .card-body {
      padding: 0;
      padding-top: 1rem;
    }
  }
</style>
