<template>
  <div class="card mb-3" style="max-width: 720px;">
    <div class="row no-gutters flex-nowrap">
      <div :class="{ 'hide-sm': showComment }">
        <div v-if="dish.url">
          <a :href="dish.url" target="_none">
            <img v-if="dish.image_url" class="card-img" :src="dish.image_url" :alt="dish.name" onerror="this.onerror=null; this.src='/img/placeholder.png'">
            <img v-else class="card-img" src="/img/placeholder.png" :alt="dish.name">
          </a>
        </div>

        <div v-else>
          <img v-if="dish.image_url" class="card-img" :src="dish.image_url" :alt="dish.name">
          <img v-else class="card-img" src="/img/placeholder.png" :alt="dish.name">
        </div>
      </div>

      <div class="card-body d-flex justify-content-between flex-column">
        <div class="mb-3">
          <h4 class="card-title">
            <a v-if="dish.url" :href="dish.url" class="text-dark" target="_none">{{ dish.name }} <font-awesome-icon v-if="!showComment" icon="external-link-alt" class="text-muted" style="width: 10px; height: 10px; vertical-align: super;" /></a>

            <template v-else class="text-secondary">{{ dish.name }}</template>
          </h4>

          <div v-if="dish.comment" class="mb-4">
            <button class="d-flex align-items-center bg-transparent border-0 p-0" @click="showComment = !showComment">Visa kommentar <font-awesome-icon v-if="!showComment" icon="chevron-down" class="ml-2" /><font-awesome-icon v-if="showComment" icon="chevron-up" class="ml-2" /></button>

            <div v-if="showComment" class="card mt-3 comment-card">
              <div v-html="dish.comment" class="card-body"></div>
            </div>
          </div>
        </div>

        <div>
          <router-link :to="{ name: 'dishes.create', query: { url: dish.url } }" class="btn btn-primary btn-sm mr-3"><font-awesome-icon icon="plus" /> Lägg till i Foodies</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDishCard',

  data () {
    return {
      showComment: false
    }
  },

  props: {
    dish: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  /* .row {
    flex-wrap: nowrap;
  } */

  .card-img {
    min-height: 150px;
    max-height: 250px;
    height: 100%;
    width: 150px;
    object-fit: cover;
  }

  @media screen and (max-width: 720px) {
    .card-img {
      width: 100px;
    }

    .card-title {
      font-size: 1.2rem;
    }

    .hide-sm {
      display: none;
    }

    .comment-card {
      border: none;
      border-top: 1px solid rgba(0,0,0,0.125);
      border-bottom: 1px solid rgba(0,0,0,0.125);
    }

    .comment-card .card-body {
      padding: 0;
      padding-top: 1rem;
    }
  }
</style>
