<template>
  <div>
    <div class="container">
      <h1 class="">
        Maträtt
        <small class="text-muted">- registrera</small>
      </h1>

      <section class="row mt-4">
        <div class="col-sm-12 col-md-8 col-lg-6">
          <div>
            <button @click="showImportForm = !showImportForm" class="btn btn-secondary">Importera via länk <font-awesome-icon v-if="!showImportForm" icon="chevron-down" class="ml-2" /><font-awesome-icon v-if="showImportForm" icon="chevron-up" class="ml-2" /></button>

            <div v-if="showImportForm" class="card bg-light mt-3">
              <div class="card-body">
                <form @submit.prevent="submitImportForm" class="">
                  <div class="form-group">
                    <label for="name">Länk</label>
                    <input v-model="importForm.url" type="url" class="form-control" id="url" required>
                  </div>

                  <button type="submit" class="btn btn-primary btn-sm-full d-block d-sm-inline-block w-100 w-sm-auto" :disabled="importLoading">
                    Importera
                    <span v-if="importLoading" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                  </button>
                </form>

                <div v-if="showImportError" class="alert alert-danger mt-3">Informationen kunde ej importeras!</div>
              </div>
            </div>
          </div>

          <hr>

          <form @submit.prevent="submitForm" class="mt-4">
            <div class="form-group">
              <label for="name">Namn</label>
              <input v-model="form.name" type="text" class="form-control" id="name" required>
            </div>

            <div class="form-group">
              <label for="url">Länk</label>
              <input v-model="form.url" type="url" class="form-control" id="url">
            </div>

            <div class="form-group">
              <label for="image_url">Bild-länk</label>
              <input v-model="form.image_url" type="url" class="form-control" id="image_url">
            </div>

            <div v-if="categories.length > 0" class="form-group">
              <label>Taggar</label>

              <div class="d-flex flex-wrap">
                <div v-for="category in categories" :key="category.id" class="form-check mr-4 mb-2">
                  <input v-model="form.categories" class="form-check-input" type="checkbox" name="categories" :value="category.id" :id="category.name" required ref="categories">
                  <label class="form-check-label" :for="category.name">
                    {{ category.name }}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="comment">Kommentar</label>
              <!-- <textarea v-model="form.comment" class="form-control" id="comment"></textarea> -->

              <ckeditor :editor="editor" :config="editorConfig" v-model="form.comment" id="comment"></ckeditor>
            </div>

            <div class="d-block d-sm-flex align-items-middle mt-4">
              <button type="submit" class="btn btn-primary btn-sm-full d-block d-sm-inline-block w-100 w-sm-auto">Spara</button>

              <router-link :to="{ name: 'dishes.index' }" class="btn btn-link d-block d-sm-inline-block text-danger mt-3 mt-sm-0">Avbryt</router-link>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/sv'

export default {
  name: 'DishCreate',

  data () {
    return {
      form: {
        name: null,
        url: null,
        image_url: null,
        categories: [],
        comment: null
      },

      importForm: {
        url: null
      },

      categories: [],

      showImportForm: false,

      showImportError: null,

      importLoading: false,

      loaded: false,
      requestError: false,

      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'insertTable' ],
        language: 'sv',
        height: 4000
      }
    }
  },

  components: {
    ckeditor: CKEditor.component
  },

  watch: {
    'form.categories' (oldVal, newVal) {
      if (this.form.categories.length === 0) {
        this.$refs['categories'].forEach(cat => {
          cat.required = true
        })
      } else {
        this.$refs['categories'].forEach(cat => {
          cat.required = false
        })
      }
    }
  },

  methods: {
    submitForm () {
      axios.post(process.env.VUE_APP_API_URI + '/dishes', this.form)
        .then(() => {
          this.$router.push({ name: 'dishes.index' })
        })
        .catch(() => {})
    },

    submitImportForm () {
      this.showImportError = false
      this.importLoading = true

      axios.get(process.env.VUE_APP_API_URI + '/dishes/fetch-meta-tags?url=' + this.importForm.url)
        .then(response => {
          this.importLoading = false
          this.showImportForm = false
          this.importForm.url = null
          this.form.name = response.data.title
          this.form.url = response.data.url
          this.form.image_url = response.data.image
        })
        .catch(() => {
          this.importLoading = false
          this.showImportError = true
        })
    },

    fetchCategories () {
      axios.get(process.env.VUE_APP_API_URI + '/categories')
        .then(response => {
          this.categories = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    }
  },

  mounted () {
    if (this.$route.query.url && this.$route.query.url.length > 0) {
      const url = this.$route.query.url

      this.showImportForm = true
      this.importForm.url = url
      this.submitImportForm()
    } else if (this.$route.query.text && this.$route.query.text.length > 0) {
      const url = this.$route.query.text

      this.showImportForm = true
      this.importForm.url = url
      this.submitImportForm()
    }

    this.fetchCategories()
  }
}
</script>
