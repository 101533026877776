<template>
  <div>
    <label :for="inputId">{{ inputLabel }}</label>

    <input v-show="!dishIsSelected" :value="value" @input="$emit('input', $event.target.value)" @focus="showSuggestions = true" @blur="setValue(null)" type="text" class="form-control" :id="inputId" autocomplete="off">

    <div v-if="dishIsSelected" class="d-flex justify-content-between">
      <input class="form-control" type="text" :value="value" disabled>
      <button @click="removeSelectedDish" class="d-flex align-items-center bg-transparent border-0 pl-3 pr-1"><font-awesome-icon icon="trash-alt" /></button>
    </div>

    <div v-if="showSuggestions && (value && value.length) > 0" class="list-group dishesList shadow-sm">
      <button type="button" v-for="dish in filteredDishes" :key="dish.id" @click="selectDish(dish)" class="list-group-item list-group-item-action bg-light">{{ dish.name }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDishSuggestionInput',

  props: {
    inputId: {
      type: String,
      required: true
    },

    inputLabel: {
      type: String,
      required: true
    },

    value: {
      type: String
    },

    course: {
      type: String
    },

    dishes: {
      type: Array,
      required: true
    },

    mappings: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      dishIsSelected: false,
      showSuggestions: false,
      requestError: false
    }
  },

  computed: {
    filteredDishes () {
      return this.dishes.slice(0).filter(dish => dish.name.toLowerCase().includes(this.value.toLowerCase()))
    }
  },

  methods: {
    setValue (dish) {
      if (dish) {
        this.$emit('input', dish.name)
        this.$emit('existingDishWasSet', { dish: dish, course: this.course })
      }

      setTimeout(() => {
        this.showSuggestions = false
      }, 200)
    },

    selectDish (dish) {
      this.setValue(dish)
      this.dishIsSelected = true
    },

    removeSelectedDish () {
      this.dishIsSelected = false
      this.value = null

      this.$emit('input', null)
      this.$emit('existingDishWasSet', { dish: null, course: this.course })
    }
  },

  mounted () {
    if (this.mappings && this.mappings[this.course]) {
      this.dishIsSelected = true
    }
  }
}
</script>

<style scoped>
  .dishesList {
    position: absolute;
    z-index: 99;
    width: 18rem;
  }
</style>
