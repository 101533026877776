<template>
  <div>
    <div class="container">
      <h1 class="mb-5">
        Inställningar
      </h1>

      <section v-if="loaded && !requestError" class="row">
        <div class="col-md-6">
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="start_weight">Startvikt</label>
              <input v-model="form.start_weight" type="number" step="0.1" class="form-control" id="start_weight" required>
            </div>

            <div class="form-group">
              <label for="goal_weight">Målvikt</label>
              <input v-model="form.goal_weight" type="number" step="0.1" class="form-control" id="goal_weight" required>
            </div>

            <button type="submit" class="btn btn-primary">Spara</button>
          </form>
        </div>
      </section>

      <div v-if="!loaded" class="spinner-container">
        <span class="spinner-border text-primary"></span>
      </div>

      <div v-if="loaded && requestError" class="alert alert-danger" role="alert">
        <strong>Något gick fel!</strong> Informationen kunde ej hämtas.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SettingIndex',

  data () {
    return {
      form: {
        start_weight: null,
        goal_weight: null
      },
      setting: {},
      loaded: false,
      requestError: false
    }
  },

  methods: {
    fetchSettings () {
      axios.get(process.env.VUE_APP_API_URI + '/settings')
        .then(response => {
          this.form.start_weight = response.data.start_weight
          this.form.goal_weight = response.data.goal_weight

          this.setting = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    submitForm () {
      axios.patch(process.env.VUE_APP_API_URI + '/settings/' + this.setting.id, this.form)
        .then(response => {
          this.$router.push({ name: 'measurements.index' })
        })
        .catch(() => {})
    }
  },

  mounted () {
    this.fetchSettings()
  }
}
</script>
