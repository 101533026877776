<template>
  <div class="container">
    <p>Du loggas ut...</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',

  methods: {
    ...mapActions(['signOut'])
  },

  async mounted () {
    await this.signOut()

    this.$router.push('/login')
  }
}
</script>
