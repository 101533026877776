import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './registerServiceWorker'
import './plugins/fontawesome'

axios.defaults.withCredentials = true

// Check if response is 401 (session expired)
// axios.interceptors.response.use(response => {
//   return response
// }, error => {
//   if (error.response.status === 401 && store.state.loggedIn) {
//     store.commit('SET_LOGGED_IN', false)

//     router.push('/login')
//   }

//   return Promise.reject(error)
// })

Vue.config.productionTip = false

// Check first if already logged in
store.dispatch('me').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
