<template>
  <div>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-5">
        <h1 class="mb-0">Sparade recept från köket.se</h1>
      </div>

      <section v-if="loaded && !requestError">
        <section class="cards">
          <app-koket-dish-card v-for="dish in sortedDishes" :key="dish.id" :dish="dish" @deletedDish="deleteDish"></app-koket-dish-card>
        </section>
      </section>

      <div v-if="!loaded" class="spinner-container">
        <span class="spinner-border text-primary"></span>
      </div>

      <div v-if="loaded && requestError" class="alert alert-danger" role="alert">
        <strong>Något gick fel!</strong> Informationen kunde ej hämtas.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AppKoketDishCard from '@/components/AppKoketDishCard'

export default {
  name: 'DishIndex',

  components: {
    AppKoketDishCard
  },

  data () {
    return {
      dishes: [],
      user: {},
      loaded: false,
      requestError: false
    }
  },

  computed: {
    dishesFixed () {
      return this.dishes.map((dish) => {
        return {
          ...dish,
          url: 'https://www.koket.se' + dish.url,
          image_url: 'https://img.koket.se/square-large' + dish.url.replace('viktvaktarna-', '') + '.jpg'
        }
      })
    },

    sortedDishes () {
      return this.dishesFixed.slice(0).sort((a, b) => a.name.localeCompare(b.name))
    }
  },

  methods: {
    fetchDishes () {
      axios.get(process.env.VUE_APP_API_URI + '/koket')
        .then(response => {
          this.dishes = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    deleteDish (dishId) {
      this.dishes = this.dishes.filter(dish => dish.id !== dishId)
    }
  },

  mounted () {
    this.fetchDishes()
  }
}
</script>
