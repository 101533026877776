<template>
  <div>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-5">
        <h1 class="mb-0">Mått</h1>

        <router-link :to="{ name: 'measurements.create' }" class="btn btn-primary"><font-awesome-icon icon="plus" class="mr-2" /> Registrera</router-link>
      </div>

      <section v-if="loaded && !requestError">
        <section class="d-flex flex-wrap align-items-center justify-content-center mb-2">
          <span class="badge badge-pill badge-info mx-2 mb-2 px2-5 text-xs">Startvikt: <strong>{{ startWeight }}</strong> kg</span>
          <span class="badge badge-pill badge-info mx-2 mb-2 px2-5 text-xs">Nuvarande vikt: <strong>{{ currentWeight }}</strong> kg</span>
          <span class="badge badge-pill badge-info mx-2 mb-2 px2-5 text-xs">Målvikt: <strong>{{ goalWeight }}</strong> kg</span>
          <span class="badge badge-pill badge-info mx-2 mb-2 px2-5 text-xs">Gått ner: <strong>{{ weightLoss }}</strong> kg</span>
          <span class="badge badge-pill badge-info mx-2 mb-2 px2-5 text-xs">Kvarstår: <strong>{{ remainingWeight }}</strong> kg</span>
        </section>

        <section>
          <div class="d-md-flex justify-content-between">
            <h2 class="mb-4">Vikt</h2>

            <div class="d-flex align-items-end">
              <div class="d-flex align-items-center">
                <span class="font-weight-bold mr-2">Filtrera:</span>
                <input v-model="filterStartDate" type="date" class="form-control form-control-sm">
                <span class="px-2">-</span>
                <input v-model="filterEndDate" type="date" class="form-control form-control-sm">
              </div>
            </div>
          </div>

          <div>
            <app-line-chart :chartData="weightData"></app-line-chart>
          </div>
        </section>

        <section v-if="!showExtraMeasurements && (breastData.labels.length || bumData.labels.length || waistData.labels.length)" class="mb-5 d-flex justify-content-center">
          <button @click="showExtraMeasurements = true" class="btn btn-outline-primary">Visa alla mått <font-awesome-icon icon="chevron-down" class="ml-2" /></button>
        </section>

        <section v-if="showExtraMeasurements">
          <div v-if="breastData.labels.length">
            <h2 class="mb-4">Bröst</h2>

            <app-line-chart :chartData="breastData"></app-line-chart>
          </div>

          <div v-if="bumData.labels.length">
            <h2 class="mb-4">Rumpa</h2>

            <app-line-chart :chartData="bumData"></app-line-chart>
          </div>

          <div v-if="waistData.labels.length">
            <h2 class="mb-4">Midja</h2>

            <app-line-chart :chartData="waistData"></app-line-chart>
          </div>
        </section>

        <section class="mb-5">
          <h2 class="mb-4">Registreringar</h2>

          <div class="table-responsive">
            <table class="table table-mobile-tight table-striped d-table d-lg-inline-block">
              <thead>
                <tr>
                  <th scope="col">Datum</th>
                  <th scope="col">
                    <span class="d-none d-sm-inline">Vikt</span>
                    <span class="d-inline d-sm-none">V</span>
                  </th>
                  <th scope="col">
                    <span class="d-none d-sm-inline">Diff</span>
                    <span class="d-inline d-sm-none">D</span>
                  </th>
                  <th scope="col" class="">
                    <span class="d-none d-sm-inline">Bröst</span>
                    <span class="d-inline d-sm-none">B</span>
                  </th>
                  <th scope="col" class="">
                    <span class="d-none d-sm-inline">Rumpa</span>
                    <span class="d-inline d-sm-none">R</span>
                  </th>
                  <th scope="col" class="">
                    <span class="d-none d-sm-inline">Midja</span>
                    <span class="d-inline d-sm-none">M</span>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody is="transition-group" name="fade">
                <tr v-for="(measurement, index) in measurements" :key="measurement.id">
                  <td>
                    <span class="d-none d-sm-inline">{{ measurement.date }}</span>
                    <span class="d-inline d-sm-none" v-html="dateShort(measurement.date)"></span>
                  </td>
                  <td>{{ measurement.weight }}</td>
                  <td>{{ weightDiff(index) }}</td>
                  <td class="">{{ measurement.breast || '-' }}</td>
                  <td class="">{{ measurement.bum || '-' }}</td>
                  <td class="">{{ measurement.waist || '-' }}</td>
                  <td>
                    <div class="d-flex flex-column d-sm-block">
                      <app-dropdown @deleteMeasurement="deleteMeasurement({ id: measurement.id, index: index })" :measurement="measurement" class="d-block d-md-none"></app-dropdown>

                      <router-link :to="{ name: 'measurements.edit', params: { id: measurement.id } }" class="d-none d-md-inline btn btn-icon text-info"><font-awesome-icon icon="edit" /></router-link>

                      <button @click="deleteMeasurement({ id: measurement.id, index: index })" class="d-none d-md-inline btn btn-icon text-danger ml-md-3"><font-awesome-icon icon="trash-alt" /></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </section>

      <div v-if="!loaded" class="spinner-container">
        <span class="spinner-border text-primary"></span>
      </div>

      <div v-if="loaded && requestError" class="alert alert-danger" role="alert">
        <strong>Något gick fel!</strong> Informationen kunde ej hämtas.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import AppLineChart from '@/components/AppLineChart'
import AppDropdown from '@/components/AppDropdown'

export default {
  name: 'MeasurementIndex',

  components: {
    AppLineChart,
    AppDropdown
  },

  data () {
    return {
      measurements: [],
      user: {},
      showExtraMeasurements: false,
      filterStartDate: null,
      filterEndDate: null,
      loaded: false,
      requestError: false
    }
  },

  computed: {
    startWeight () {
      // eslint-disable-next-line camelcase
      return this.user.settings ? this.user.settings[0].start_weight : 0
    },

    goalWeight () {
      // eslint-disable-next-line camelcase
      return this.user.settings ? this.user.settings[0].goal_weight : 0
    },

    currentWeight () {
      return this.measurements.length > 0 ? this.measurements[0].weight : 0
    },

    weightLoss () {
      return Math.round((this.startWeight - this.currentWeight) * 10) / 10
    },

    remainingWeight () {
      return Math.round((this.currentWeight - this.goalWeight) * 10) / 10
    },

    testParsedStartDate () {
      return dayjs(this.filterStartDate).valueOf()
    },

    testParsedEndDate () {
      return dayjs(this.filterEndDate).valueOf()
    },

    testParsedMeasurementDate () {
      return dayjs(this.measurements[this.measurements.length - 1].date).valueOf()
    },

    filteredMeasurements () {
      const filterStartDateInMs = dayjs(this.filterStartDate || '1970-01-01').valueOf()
      const filterEndDateInMs = dayjs(this.filterEndDate || '2050-12-31').valueOf()

      if (this.filterStartDate || this.filterEndDate) {
        return this.measurements.filter(item => {
          return (filterStartDateInMs < dayjs(item.date).valueOf() && dayjs(item.date).valueOf() < filterEndDateInMs)
        })
      }

      return this.measurements
    },

    weightData () {
      const dates = this.filteredMeasurements.map(measurement => measurement.date).reverse()
      const weight = this.filteredMeasurements.map(measurement => measurement.weight).reverse()

      return {
        labels: dates,
        series: [ weight ]
      }
    },

    breastData () {
      const filtered = this.measurements.filter(measurement => measurement.breast)
      const dates = filtered.map(measurement => measurement.date).reverse()
      const breast = filtered.map(measurement => measurement.breast).reverse()

      return {
        labels: dates,
        series: [ breast ]
      }
    },

    bumData () {
      const filtered = this.measurements.filter(measurement => measurement.bum)
      const dates = filtered.map(measurement => measurement.date).reverse()
      const bum = filtered.map(measurement => measurement.bum).reverse()

      return {
        labels: dates,
        series: [ bum ]
      }
    },

    waistData () {
      const filtered = this.measurements.filter(measurement => measurement.waist)
      const dates = filtered.map(measurement => measurement.date).reverse()
      const waist = filtered.map(measurement => measurement.waist).reverse()

      return {
        labels: dates,
        series: [ waist ]
      }
    },

    chartMax () {
      return this.measurements.slice(0).sort((a, b) => b.weight - a.weight)[0].weight
    },

    chartMin () {
      return this.measurements.slice(0).sort((a, b) => a.weight - b.weight)[0].weight
    }
  },

  methods: {
    fetchMeasurements () {
      axios.get(process.env.VUE_APP_API_URI + '/measurements')
        .then(response => {
          this.measurements = response.data.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    fetchUser () {
      axios.get(process.env.VUE_APP_API_URI + '/user')
        .then(response => {
          this.user = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    deleteMeasurement (data) {
      if (!confirm('Är du säker?')) {
        return
      }

      axios.delete(process.env.VUE_APP_API_URI + '/measurements/' + data.id, {})
        .then(response => {
          this.measurements = this.measurements.filter(measurement => measurement.id !== data.id)
        })
        .catch(() => {})
    },

    dateShort (date) {
      return dayjs(date).format('D/M') + ' <span class="text-sm">' + dayjs(date).format('-YY') + '</span>'
    },

    weightDiff (rowIndex) {
      const currentWeight = new Decimal(parseFloat(this.measurements[rowIndex].weight))
      const prevWeight = this.measurements[rowIndex + 1] ? parseFloat(this.measurements[rowIndex + 1].weight) : null
      let diff = '-'

      if (prevWeight) {
        // diff = Math.floor((currentWeight - prevWeight) * 100) / 100
        diff = currentWeight.minus(prevWeight)

        diff = diff > 0 ? '+' + diff : diff
      }

      return diff
    }
  },

  watch: {
    filterStartDate (newDate, oldDate) {
      localStorage.setItem('foodiesFilterStartDate', newDate)
    },

    filterEndDate (newDate, oldDate) {
      localStorage.setItem('foodiesFilterEndDate', newDate)
    }
  },

  mounted () {
    this.fetchMeasurements()
    this.fetchUser()

    if (localStorage.getItem('foodiesFilterStartDate')) {
      this.filterStartDate = localStorage.getItem('foodiesFilterStartDate')
    }

    if (localStorage.getItem('foodiesFilterEndDate')) {
      this.filterEndDate = localStorage.getItem('foodiesFilterEndDate')
    }
  }
}
</script>
