<template>
  <div class="container">
    <h1 class="mb-5">
      Logga in
    </h1>

    <section class="row">
      <div class="col-md-6">
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="email">E-postadress</label>
            <input v-model="form.email" :class="{ 'is-invalid': error }" type="email" class="form-control" id="email" required>
            <small v-if="error" class="form-text text-danger">E-postadressen eller lösenordet är fel.</small>
          </div>

          <div class="form-group">
            <label for="password">Lösenord</label>
            <input v-model="form.password" :class="{ 'is-invalid': error }" type="password" class="form-control" id="password" required>
          </div>

          <div class="form-check mb-4">
            <input v-model="form.remember" class="form-check-input" type="checkbox" id="remember">
            <label for="remember" class="form-check-label">Kom ihåg mig</label>
          </div>

          <button type="submit" class="btn btn-primary">Logga in</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',

  data () {
    return {
      form: {
        email: null,
        password: null,
        remember: false
      },
      error: false
    }
  },

  methods: {
    ...mapActions(['signIn']),

    login () {
      this.error = false

      const redirectTo = this.$route.query.redirect ? this.$route.query.redirect : null

      this.signIn(this.form).then(() => {
        if (redirectTo) {
          this.$router.push(redirectTo)
        } else {
          this.$router.push('/')
        }
      })
        .catch(() => {
          this.error = true
        })
    }
  }
}
</script>
