<template>
  <div>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between mb-5">
        <h1 class="mb-0">Maträtter</h1>

        <router-link :to="{ name: 'dishes.create' }" class="btn btn-primary"><font-awesome-icon icon="plus" class="mr-2" /> Ny</router-link>
      </div>

      <section v-if="loaded && !requestError">
        <section class="filter">
          <AppFilterButton @filterChanged="updateFilter" title="Alla" :filterValue="0" :checked="filterCategories.includes(0)" />
          <AppFilterButton @filterChanged="updateFilter" v-for="category in categories" :key="category.id" :title="category.name" :filterValue="category.id" :checked="filterCategories.includes(category.id)" />
        </section>

        <section class="cards mt-3">
          <app-dish-card v-for="dish in sortedDishes" :key="dish.id" :dish="dish" @deletedDish="deleteDish"></app-dish-card>
        </section>
      </section>

      <div v-if="!loaded" class="spinner-container">
        <span class="spinner-border text-primary"></span>
      </div>

      <div v-if="loaded && requestError" class="alert alert-danger" role="alert">
        <strong>Något gick fel!</strong> Informationen kunde ej hämtas.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AppDishCard from '@/components/AppDishCard'
import AppFilterButton from '@/components/AppFilterButton'

export default {
  name: 'DishIndex',

  components: {
    AppDishCard,
    AppFilterButton
  },

  data () {
    return {
      dishes: [],
      categories: [],
      user: {},
      filterCategories: [],
      loaded: false,
      requestError: false
    }
  },

  computed: {
    sortedDishes () {
      return this.filteredDishes.slice(0).sort((a, b) => a.name.localeCompare(b.name))
    },

    filteredDishes () {
      if (this.filterCategories.length === 1 && this.filterCategories[0] === 0) {
        return this.dishes
      } else {
        return this.dishes.slice(0).filter(dish => this.filterCategories.filter(catId => dish.category_ids.includes(catId)).length > 0)
      }
    }
  },

  methods: {
    updateFilter (catId) {
      if (catId !== 0 && this.filterCategories.includes(0)) {
        this.filterCategories.splice(this.filterCategories.indexOf(0), 1)
      }

      if (catId === 0) {
        this.filterCategories = []
        this.filterCategories.push(0)
      } else if (!this.filterCategories.includes(catId)) {
        this.filterCategories.push(catId)
      } else {
        this.filterCategories.splice(this.filterCategories.indexOf(catId), 1)
      }

      if (this.filterCategories.length === 0) {
        this.filterCategories.push(0)
      }
    },

    fetchDishes () {
      axios.get(process.env.VUE_APP_API_URI + '/dishes')
        .then(response => {
          this.dishes = response.data.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    fetchCategories () {
      axios.get(process.env.VUE_APP_API_URI + '/categories')
        .then(response => {
          this.categories = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    fetchUser () {
      axios.get(process.env.VUE_APP_API_URI + '/user')
        .then(response => {
          this.user = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    deleteDish (dishId) {
      this.dishes = this.dishes.filter(dish => dish.id !== dishId)
    }
  },

  mounted () {
    this.fetchDishes()
    this.fetchCategories()
    this.fetchUser()

    this.filterCategories.push(0)
  }
}
</script>
