import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faWeight,
  faCalendarAlt,
  faPizzaSlice,
  faPlus,
  faEdit,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faEllipsisH,
  faExternalLinkAlt,
  faArrowAltCircleRight,
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons'

import {
  faSquare
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faWeight,
  faCalendarAlt,
  faPizzaSlice,
  faPlus,
  faEdit,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faEllipsisH,
  faExternalLinkAlt,
  faArrowAltCircleRight,
  faCheckSquare,
  faSquare
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
