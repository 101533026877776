<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-light mb-5">
      <div class="container">
        <router-link :to="{ name: 'measurements.index'}" class="navbar-brand d-flex align-items-center mr-4">
          <span class="mr-3 d-flex justify-content-center align-items-middle bg-primary p-2 rounded">
            <font-awesome-icon icon="pizza-slice" class="text-white" style="font-size: 1.5rem;" />
          </span>

          <h1 class="mb-0 h4">Foodies</h1>
        </router-link>

        <button @click="showNav = !showNav" class="navbar-toggler" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div :class="{ 'show': showNav }" class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link :to="{ name: 'measurements.index'}" class="nav-link py-3 py-sm-2"><font-awesome-icon icon="weight" class="mr-2" /> Mått</router-link>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'dishes.index'}" class="nav-link py-3 py-sm-2"><font-awesome-icon icon="pizza-slice" class="mr-2" /> Maträtter</router-link>
            </li>

            <li class="nav-item koket-item">
              <router-link :to="{ name: 'koket.index'}" class="nav-link py-3 py-sm-2"><img src="/img/koket_icon.png" alt="Köket.se"> Recept från köket.se</router-link>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'weekplans.index'}" class="nav-link py-3 py-sm-2"><font-awesome-icon icon="calendar-alt" class="mr-2" /> Veckoplanering</router-link>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'settings.index'}" class="nav-link py-3 py-sm-2"><font-awesome-icon icon="sliders-h" class="mr-2" /> Inställningar</router-link>
            </li>
          </ul>

          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link v-if="!loggedIn" :to="{ name: 'login'}" class="nav-link py-3 py-sm-2"><font-awesome-icon icon="sign-in-alt" class="mr-2 " /> Logga in</router-link>
              <router-link v-else :to="{ name: 'logout'}" class="nav-link py-3 py-sm-2"><font-awesome-icon icon="sign-out-alt" class="mr-2 " /> Logga ut</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppNavbar',

  data () {
    return {
      showNav: false
    }
  },

  watch: {
    '$route' () {
      this.showNav = false
    }
  },

  computed: {
    ...mapState(['loggedIn'])
  }
}
</script>

<style>
  .navbar-collapse.show {
    margin-top: 20px;
  }

  .koket-item img {
    width: 1.2em;
    vertical-align: -0.125em;
    margin-right: .4em;
    filter: grayscale(100%);
  }
</style>
