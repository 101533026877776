<template>
  <div>
    <div class="container">
      <h1 class="mb-5">
        Vecko&shy;planering
        <small class="text-muted">- redigera</small>
      </h1>

      <form v-if="loaded && !fetchWeekplanError" @submit.prevent="submitForm" class="max-w-2xl">
        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="date">Veckans startdatum (måndag)</label>
              <input v-model="form.start_date" type="date" class="form-control" id="date" required>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Måndag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.monday_lunch" course="monday_lunch" inputId="mondayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
                <app-dish-suggestion-input v-model="form.monday_dinner" course="monday_dinner" inputId="mondayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Tisdag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.tuesday_lunch" course="tuesday_lunch" inputId="tuesdayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.tuesday_dinner" course="tuesday_dinner" inputId="tuesdayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Onsdag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.wednesday_lunch" course="wednesday_lunch" inputId="wednesdayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.wednesday_dinner" course="wednesday_dinner" inputId="wednesdayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Torsdag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.thursday_lunch" course="thursday_lunch" inputId="thursdayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.thursday_dinner" course="thursday_dinner" inputId="thursdayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Fredag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.friday_lunch" course="friday_lunch" inputId="fridayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.friday_dinner" course="friday_dinner" inputId="fridayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Lördag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.saturday_lunch" course="saturday_lunch" inputId="saturdayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.saturday_dinner" course="saturday_dinner" inputId="saturdayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <h2 class="h5 mt-3">Söndag</h2>

        <section class="row">
          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.sunday_lunch" course="sunday_lunch" inputId="sundayLunch" inputLabel="Lunch" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <app-dish-suggestion-input v-model="form.sunday_dinner" course="sunday_dinner" inputId="sundayDinner" inputLabel="Middag" :dishes="dishes" :mappings="form.mappings" @existingDishWasSet="setMapping"></app-dish-suggestion-input>
            </div>
          </div>
        </section>

        <div class="d-block d-sm-flex align-items-middle mt-4">
          <button type="submit" class="btn btn-primary d-block d-sm-inline-block w-100 w-sm-auto">Spara</button>

          <router-link :to="{ name: 'weekplans.index' }" class="btn btn-link d-block d-sm-inline-block text-danger mt-3 mt-sm-0">Avbryt</router-link>
        </div>
      </form>

      <div v-if="loaded && fetchWeekplanError" class="alert alert-danger" role="alert">
        <strong>Något gick fel!</strong> Informationen kunde ej hämtas.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import AppDishSuggestionInput from '@/components/AppDishSuggestionInput'

export default {
  name: 'WeekplanEdit',

  components: {
    AppDishSuggestionInput
  },

  data () {
    return {
      form: {
        start_date: null,
        monday_lunch: null,
        monday_dinner: null,
        tuesday_lunch: null,
        tuesday_dinner: null,
        wednesday_lunch: null,
        wednesday_dinner: null,
        thursday_lunch: null,
        thursday_dinner: null,
        friday_lunch: null,
        friday_dinner: null,
        saturday_lunch: null,
        saturday_dinner: null,
        sunday_lunch: null,
        sunday_dinner: null,
        mappings: {}
      },

      dishes: [],

      loaded: false,

      fetchWeekplanError: false
    }
  },

  methods: {
    submitForm () {
      if (!this.validateStartDateIsMonday(this.form.start_date)) {
        alert('Startdatumet måste vara en måndag!')

        return
      }

      const courses = JSON.parse(JSON.stringify(this.form))

      delete courses['start_date']
      delete courses['mappings']

      for (const course in courses) {
        if (this.form[course] === null || this.form[course] === '') {
          this.form.mappings[course] = null
        }
      }

      axios.patch(process.env.VUE_APP_API_URI + '/weekplans/' + this.$route.params.id, this.form)
        .then(response => {
          this.$router.push({ name: 'weekplans.index' })
        })
        .catch(() => {})
    },

    fetchDishes () {
      axios.get(process.env.VUE_APP_API_URI + '/dishes')
        .then(response => {
          this.dishes = response.data.data

          this.loaded = true
        })
        .catch(() => {
          this.requestError = true
        })
    },

    validateStartDateIsMonday (startDate) {
      return dayjs(startDate).day() === 1
    },

    setMapping (data) {
      this.form.mappings[data.course] = data.dish ? data.dish.id : null
    }
  },

  mounted () {
    axios.get(process.env.VUE_APP_API_URI + '/weekplans/' + this.$route.params.id)
      .then(response => {
        this.form.start_date = response.data.data.start_date

        this.form.monday_lunch = response.data.data.monday_lunch
        this.form.monday_dinner = response.data.data.monday_dinner
        this.form.tuesday_lunch = response.data.data.tuesday_lunch
        this.form.tuesday_dinner = response.data.data.tuesday_dinner
        this.form.wednesday_lunch = response.data.data.wednesday_lunch
        this.form.wednesday_dinner = response.data.data.wednesday_dinner
        this.form.thursday_lunch = response.data.data.thursday_lunch
        this.form.thursday_dinner = response.data.data.thursday_dinner
        this.form.friday_lunch = response.data.data.friday_lunch
        this.form.friday_dinner = response.data.data.friday_dinner
        this.form.saturday_lunch = response.data.data.saturday_lunch
        this.form.saturday_dinner = response.data.data.saturday_dinner
        this.form.sunday_lunch = response.data.data.sunday_lunch
        this.form.sunday_dinner = response.data.data.sunday_dinner

        this.form.mappings = response.data.data.mappings

        this.loaded = true
      })
      .catch(() => {
        this.loaded = true
        this.fetchWeekplanError = true
      })

    this.fetchDishes()
  }
}
</script>
