<template>
  <div id="app" class="pb-5">
    <app-navbar></app-navbar>

    <router-view/>
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue'

export default {
  name: 'App',

  components: {
    AppNavbar
  }
}
</script>

<style>
  @import url('https://stackpath.bootstrapcdn.com/bootswatch/4.4.1/litera/bootstrap.min.css');
  @import url('https://fonts.googleapis.com/css?family=DM+Sans:400,700&display=swap');

  * { font-family: 'DM Sans', sans-serif !important; }

  .underline { text-decoration: underline; }

  .text-xs { font-size: .75rem; }
  .text-sm { font-size: .875rem; }
  .text-base { font-size: 1rem; }
  .text-2xl { font-size: 1.5rem; }

  .px2-5 { padding-left: 0.75rem; padding-right: 0.75rem; }

  .max-w-2xl { max-width: 42rem; }

  .btn { border-radius: 5px !important; }

  .btn.btn-md { padding: 0.5rem 0.75rem !important; }

  .btn.btn-icon {
    font-size: 1.1rem;
    padding: 0px 10px;
  }

  .btn.btn-sm-full { width: 100%; }

  .btn-sm { font-size: 0.875rem !important; }

  @media only screen and (min-width: 576px) {
    .btn.btn-sm-full { width: auto; }
    .w-sm-auto { width: auto !important; }
  }

  .btn-mini {
    width: 30px;
    height: 28px;
  }

  button.navbar-toggler { border: none; }

  div.spinner-container { min-height: 40px; }

  @media only screen and (max-width: 575px) {
    .table-mobile-tight th, .table-mobile-tight td {
      /* font-size: .85rem; */
      padding: 0.5rem 0.3rem;
    }
  }

  .fade-enter-active, .fade-leave-active { transition: opacity .2s; }

  .fade-enter, .fade-leave-to { opacity: 0; }

  svg.ct-chart-bar, svg.ct-chart-line { overflow: visible; }

  .ct-label.ct-label.ct-horizontal.ct-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space: nowrap;
  }
</style>
