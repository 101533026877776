<template>
  <div>
    <div class="container">
      <h1 class="mb-5">
        Mått
        <small class="text-muted">- registrera</small>
      </h1>

      <section class="row">
        <div class="col-sm-6 col-md-5 col-lg-4 col-xl-3">
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="date">Datum</label>
              <input v-model="form.date" type="date" class="form-control" id="date" required>
            </div>

            <div class="form-group">
              <label for="weight">Vikt</label>
              <input v-model="form.weight" type="number" step="0.1" class="form-control" id="weight" required>
            </div>

            <div class="form-group">
              <label for="breast">Bröst</label>
              <input v-model="form.breast" type="number" step="0.1" class="form-control" id="breast">
            </div>

            <div class="form-group">
              <label for="bum">Rumpa</label>
              <input v-model="form.bum" type="number" step="0.1" class="form-control" id="bum">
            </div>

            <div class="form-group">
              <label for="waist">Midja</label>
              <input v-model="form.waist" type="number" step="0.1" class="form-control" id="waist">
            </div>

            <div class="d-block d-sm-flex align-items-middle mt-4">
              <button type="submit" class="btn btn-primary btn-sm-full d-block d-sm-inline-block w-100 w-sm-auto">Spara</button>

              <router-link :to="{ name: 'measurements.index' }" class="btn btn-link d-block d-sm-inline-block text-danger mt-3 mt-sm-0">Avbryt</router-link>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MeasurementCreate',

  data () {
    return {
      form: {
        date: null,
        weight: null,
        breast: null,
        bum: null,
        waist: null
      }
    }
  },

  methods: {
    submitForm () {
      axios.post(process.env.VUE_APP_API_URI + '/measurements', this.form)
        .then(response => {
          this.$router.push({ name: 'measurements.index' })
        })
        .catch(() => {})
    }
  },

  mounted () {
    this.form.date = new Date(Date.now()).toISOString().split('T')[0]
  }
}
</script>
