<template>
  <div>
    <div class="dropdown dropleft">
      <button @click="showDropdown = !showDropdown" class="btn btn-sm btn-mini btn-secondary p-0">
        <font-awesome-icon icon="ellipsis-h" />
      </button>

      <div :class="{ 'show': showDropdown }" class="dropdown-menu">
        <router-link :to="{ name: 'measurements.edit', params: { id: measurement.id } }" class="dropdown-item mr-1"><font-awesome-icon icon="edit" /> Redigera</router-link>

        <button @click="deleteMeasurement()" class="dropdown-item mr-1"><font-awesome-icon icon="trash-alt" /> Ta bort</button>
      </div>
    </div>

    <div v-if="showDropdown" @click="showDropdown = false" class="dropdown-overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'AppDropdown',

  props: {
    measurement: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showDropdown: false
    }
  },

  methods: {
    deleteMeasurement () {
      this.showDropdown = false

      this.$emit('deleteMeasurement')
    }
  }
}
</script>

<style scoped>
  .dropdown-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    /* background: rgba(0,0,0,0.1); */
  }
</style>
