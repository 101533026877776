import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import MeasurementIndex from '@/views/Measurement/Index.vue'
import MeasurementCreate from '@/views/Measurement/Create.vue'
import MeasurementEdit from '@/views/Measurement/Edit.vue'

import DishIndex from '@/views/Dish/Index.vue'
import DishCreate from '@/views/Dish/Create.vue'
import DishEdit from '@/views/Dish/Edit.vue'

import KoketIndex from '@/views/Koket/Index.vue'

import WeekplanIndex from '@/views/Weekplan/Index.vue'
import WeekplanCreate from '@/views/Weekplan/Create.vue'
import WeekplanEdit from '@/views/Weekplan/Edit.vue'

import SettingIndex from '@/views/Setting/Index.vue'

import Login from '@/views/Auth/Login.vue'
import Logout from '@/views/Auth/Logout.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/dishes', meta: { requiresAuth: true } },

  { path: '/measurements', name: 'measurements.index', component: MeasurementIndex, meta: { requiresAuth: true } },
  { path: '/measurements/create', name: 'measurements.create', component: MeasurementCreate, meta: { requiresAuth: true } },
  { path: '/measurements/:id/edit', name: 'measurements.edit', component: MeasurementEdit, meta: { requiresAuth: true } },

  { path: '/dishes', name: 'dishes.index', component: DishIndex, meta: { requiresAuth: true } },
  { path: '/dishes/create', name: 'dishes.create', component: DishCreate, meta: { requiresAuth: true } },
  { path: '/dishes/:id/edit', name: 'dishes.edit', component: DishEdit, meta: { requiresAuth: true } },

  { path: '/koket', name: 'koket.index', component: KoketIndex, meta: { requiresAuth: true } },

  { path: '/weekplans', name: 'weekplans.index', component: WeekplanIndex, meta: { requiresAuth: true } },
  { path: '/weekplans/create', name: 'weekplans.create', component: WeekplanCreate, meta: { requiresAuth: true } },
  { path: '/weekplans/:id/edit', name: 'weekplans.edit', component: WeekplanEdit, meta: { requiresAuth: true } },

  { path: '/settings', name: 'settings.index', component: SettingIndex, meta: { requiresAuth: true } },

  { path: '/login', name: 'login', component: Login },
  { path: '/logout', name: 'logout', component: Logout, meta: { requiresAuth: true } }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    if (to.name === 'login' && store.state.loggedIn) {
      next('/')
    }

    next()
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'login') {
//     if (store.state.loggedIn) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     if (store.state.loggedIn) {
//       next('/')
//     } else {
//       next()
//     }
//   }
// })

export default router
