<template>
  <div>
    <div class="container">
      <h1 class="mb-5">
        Maträtt
        <small class="text-muted">- redigera</small>
      </h1>

      <section class="row">
        <div class="col-sm-12 col-md-8 col-lg-6">
          <form v-if="loaded" @submit.prevent="submitForm">
            <div class="form-group">
              <label for="name">Namn</label>
              <input v-model="form.name" type="text" class="form-control" id="name" required>
            </div>

            <div class="form-group">
              <label for="url">Länk</label>
              <input v-model="form.url" type="url" class="form-control" id="url">
            </div>

            <div class="form-group">
              <label for="image_url">Bild-länk</label>
              <input v-model="form.image_url" type="url" class="form-control" id="image_url">
            </div>

            <div class="form-group">
              <label>Taggar</label>

              <div class="d-flex flex-wrap">
                <div v-for="category in categories" :key="category.id" class="form-check mr-4 mb-2">
                  <input v-model="form.categories" class="form-check-input" type="checkbox" name="categories" :value="category.id" :id="category.name" checked required ref="categories">
                  <label class="form-check-label" :for="category.name">
                    {{ category.name }}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="comment">Kommentar</label>
              <ckeditor :editor="editor" :config="editorConfig" v-model="form.comment" id="comment"></ckeditor>
            </div>

            <div class="d-block d-sm-flex align-items-middle mt-4">
              <button type="submit" class="btn btn-primary btn-sm-full">Spara</button>

              <router-link :to="{ name: 'dishes.index' }" class="btn btn-link btn-sm-full text-danger mt-3 mt-sm-0">Avbryt</router-link>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'DishEdit',

  data () {
    return {
      form: {
        name: null,
        url: null,
        image_url: null,
        categories: [],
        comment: null
      },

      categories: [],

      loaded: false,

      editor: ClassicEditor,
      editorData: this.initialComment,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'insertTable' ]
      }
    }
  },

  components: {
    ckeditor: CKEditor.component
  },

  computed: {
    initialComment () {
      return this.comment ? this.comment : null
    }
  },

  methods: {
    submitForm () {
      axios.patch(process.env.VUE_APP_API_URI + '/dishes/' + this.$route.params.id, this.form)
        .then(() => {
          this.$router.push({ name: 'dishes.index' })
        })
        .catch(() => {})
    },

    fetchCategories () {
      axios.get(process.env.VUE_APP_API_URI + '/categories')
        .then(response => {
          this.categories = response.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    }
  },

  watch: {
    'form.categories' (oldVal, newVal) {
      if (this.form.categories.length === 0) {
        this.$refs['categories'].forEach(cat => {
          cat.required = true
        })
      } else {
        this.$refs['categories'].forEach(cat => {
          cat.required = false
        })
      }
    }
  },

  mounted () {
    axios.get(process.env.VUE_APP_API_URI + '/dishes/' + this.$route.params.id)
      .then(response => {
        this.form.name = response.data.data.name
        this.form.url = response.data.data.url
        this.form.image_url = response.data.data.image_url
        this.form.categories = response.data.data.categories.map(cat => cat.id)
        this.form.comment = response.data.data.comment

        this.loaded = true
      })
      .catch(() => {})

    this.fetchCategories()
  }
}
</script>
