import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false
    // user: {}
  },

  mutations: {
    SET_LOGGED_IN (state, loggedIn) {
      state.loggedIn = loggedIn
    }

    // SET_USER (state, user) {
    //   state.user = user
    // }
  },

  actions: {
    async signIn ({ dispatch }, credentials) {
      await axios.get(process.env.VUE_APP_API_BASE_URI + '/airlock/csrf-cookie')
      await axios.post(process.env.VUE_APP_API_BASE_URI + '/login', credentials)

      return dispatch('me')
    },

    async signOut ({ dispatch }) {
      await axios.post(process.env.VUE_APP_API_BASE_URI + '/logout')

      return dispatch('me')
    },

    me ({ commit }) {
      return axios.get(process.env.VUE_APP_API_URI + '/user').then(response => {
        commit('SET_LOGGED_IN', true)
        // commit('SET_USER', true)
      }).catch(() => {
        commit('SET_LOGGED_IN', false)
      })
    }
  }
})
