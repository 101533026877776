<template>
  <div>
    <div class="container">
      <div class="mb-5">
        <h1 class="mb-5">Vecko&shy;planering</h1>

        <div>
          <router-link :to="{ name: 'weekplans.create', query: { startDate: nextWeeksMonday } }" class="btn btn-primary d-block d-md-inline-block"><font-awesome-icon icon="plus" class="mr-2" /> Nästa vecka (v. {{ nextWeekNr }})</router-link>

          <router-link :to="{ name: 'weekplans.create', query: { startDate: currentWeeksMonday } }" class="btn btn-outline-primary d-block d-md-inline-block mt-3 mt-md-0 ml-0 ml-md-3"><font-awesome-icon icon="plus" class="mr-2" /> Denna vecka (v. {{ currentWeekNr }})</router-link>

          <router-link :to="{ name: 'weekplans.create' }" class="btn btn-outline-info d-block d-md-inline-block mt-3 mt-md-0 ml-0 ml-md-3"><font-awesome-icon icon="plus" class="mr-2" /> Valfri vecka</router-link>
        </div>
      </div>

      <section v-if="loaded && !requestError">
        <section v-if="weekplans.length > 0" class="max-w-2xl">
          <div v-for="(weekplan, index) in weekplans" :key="weekplan.id" class="card mt-5">
            <div class="card-header bg-primary text-white d-flex align-items-center justify-content-between">
              <h2 class="text-2xl mb-0">Vecka {{ weekplan.week_nr }} <small class="ml-2">{{ weekplan.year }}</small></h2>

              <div>
                <router-link :to="{ name: 'weekplans.edit', params: { id: weekplan.id } }" class="btn btn-info btn-md"><font-awesome-icon icon="edit" /></router-link>

                <button @click="deleteWeekplan({ id: weekplan.id, index: index })" class="btn btn-danger btn-md ml-3"><font-awesome-icon icon="trash-alt" /></button>
              </div>
            </div>

            <div class="card-body p-0">
              <section v-if="weekplan.monday_lunch || weekplan.monday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Måndag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.monday_lunch && weekplan.mappings.monday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.monday_lunch; showDishModal = true" href="#">{{ weekplan.monday_lunch }}</a>
                    <span v-else-if="weekplan.monday_lunch">{{ weekplan.monday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.monday_dinner && weekplan.mappings.monday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.monday_dinner; showDishModal = true" href="#">{{ weekplan.monday_dinner }}</a>
                    <span v-else-if="weekplan.monday_dinner">{{ weekplan.monday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>

              <section v-if="weekplan.tuesday_lunch || weekplan.tuesday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Tisdag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.tuesday_lunch && weekplan.mappings.tuesday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.tuesday_lunch; showDishModal = true" href="#">{{ weekplan.tuesday_lunch }}</a>
                    <span v-else-if="weekplan.tuesday_lunch">{{ weekplan.tuesday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.tuesday_dinner && weekplan.mappings.tuesday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.tuesday_dinner; showDishModal = true" href="#">{{ weekplan.tuesday_dinner }}</a>
                    <span v-else-if="weekplan.tuesday_dinner">{{ weekplan.tuesday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>

              <section v-if="weekplan.wednesday_lunch || weekplan.wednesday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Onsdag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.wednesday_lunch && weekplan.mappings.wednesday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.wednesday_lunch; showDishModal = true" href="#">{{ weekplan.wednesday_lunch }}</a>
                    <span v-else-if="weekplan.wednesday_lunch">{{ weekplan.wednesday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.wednesday_dinner && weekplan.mappings.wednesday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.wednesday_dinner; showDishModal = true" href="#">{{ weekplan.wednesday_dinner }}</a>
                    <span v-else-if="weekplan.wednesday_dinner">{{ weekplan.wednesday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>

              <section v-if="weekplan.thursday_lunch || weekplan.thursday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Torsdag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.thursday_lunch && weekplan.mappings.thursday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.thursday_lunch; showDishModal = true" href="#">{{ weekplan.thursday_lunch }}</a>
                    <span v-else-if="weekplan.thursday_lunch">{{ weekplan.thursday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.thursday_dinner && weekplan.mappings.thursday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.thursday_dinner; showDishModal = true" href="#">{{ weekplan.thursday_dinner }}</a>
                    <span v-else-if="weekplan.thursday_dinner">{{ weekplan.thursday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>

              <section v-if="weekplan.friday_lunch || weekplan.friday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Fredag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.friday_lunch && weekplan.mappings.friday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.friday_lunch; showDishModal = true" href="#">{{ weekplan.friday_lunch }}</a>
                    <span v-else-if="weekplan.friday_lunch">{{ weekplan.friday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.friday_dinner && weekplan.mappings.friday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.friday_dinner; showDishModal = true" href="#">{{ weekplan.friday_dinner }}</a>
                    <span v-else-if="weekplan.friday_dinner">{{ weekplan.friday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>

              <section v-if="weekplan.saturday_lunch || weekplan.saturday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Lördag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.saturday_lunch && weekplan.mappings.saturday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.saturday_lunch; showDishModal = true" href="#">{{ weekplan.saturday_lunch }}</a>
                    <span v-else-if="weekplan.saturday_lunch">{{ weekplan.saturday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.saturday_dinner && weekplan.mappings.saturday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.saturday_dinner; showDishModal = true" href="#">{{ weekplan.saturday_dinner }}</a>
                    <span v-else-if="weekplan.saturday_dinner">{{ weekplan.saturday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>

              <section v-if="weekplan.sunday_lunch || weekplan.sunday_dinner">
                <div class="px-3 py-2 font-weight-bold text-base bg-light">Söndag</div>

                <div class="row px-3 py-2">
                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Lunch</small></span>
                    <a v-if="weekplan.sunday_lunch && weekplan.mappings.sunday_lunch" @click.prevent="dishModalDishId = weekplan.mappings.sunday_lunch; showDishModal = true" href="#">{{ weekplan.sunday_lunch }}</a>
                    <span v-else-if="weekplan.sunday_lunch">{{ weekplan.sunday_lunch }}</span>
                    <span v-else>-</span>
                  </div>

                  <div class="col d-flex flex-column">
                    <span class="underline"><small>Middag</small></span>
                    <a v-if="weekplan.sunday_dinner && weekplan.mappings.sunday_dinner" @click.prevent="dishModalDishId = weekplan.mappings.sunday_dinner; showDishModal = true" href="#">{{ weekplan.sunday_dinner }}</a>
                    <span v-else-if="weekplan.sunday_dinner">{{ weekplan.sunday_dinner }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <section v-else>Inga veckoplaneringar här inte...</section>
      </section>

      <div v-if="!loaded" class="spinner-container">
        <span class="spinner-border text-primary"></span>
      </div>

      <div v-if="loaded && requestError" class="alert alert-danger" role="alert">
        <strong>Något gick fel!</strong> Informationen kunde ej hämtas.
      </div>
    </div>

    <app-dish-modal v-if="showDishModal" :dishId="dishModalDishId" :useFooter="false" @close="showDishModal = false">
      <div class="text-right">
        asdf
      </div>
    </app-dish-modal>
  </div>
</template>

<script>
import AppDishModal from '@/components/AppDishModal'
import axios from 'axios'
import dayjs from 'dayjs'
// import 'dayjs/locale/sv'
// import weekYear from 'dayjs/plugin/weekYear'
import isoWeek from 'dayjs/plugin/isoWeek'
// var isoWeek = require('dayjs/plugin/isoWeek')

// dayjs.extend(weekYear)
// dayjs.locale('sv')
dayjs.extend(isoWeek)

export default {
  name: 'WeekplanIndex',

  components: {
    AppDishModal
  },

  data () {
    return {
      weekplans: [],
      dishModalDishId: null,
      showDishModal: false,
      loaded: false,
      requestError: false
    }
  },

  computed: {
    currentWeekNr () {
      return dayjs().isoWeek()
    },

    testWeek () {
      return {
        'dayjs.locale()': dayjs.locale(),
        'dayjs("2021-02-28").isoWeek()': dayjs('2021-02-28').isoWeek(),
        'dayjs("2021-02-28").day(0).format("YYYY-MM-DD")': dayjs('2021-02-28').day(0).format('YYYY-MM-DD'),
        'dayjs("2021-02-28").day(1).format("YYYY-MM-DD")': dayjs('2021-02-28').day(1).format('YYYY-MM-DD'),
        'dayjs("2021-02-28").isoWeekday(0).format("YYYY-MM-DD")': dayjs('2021-02-28').isoWeekday(0).format('YYYY-MM-DD'),
        'dayjs("2021-02-28").isoWeekday(1).format("YYYY-MM-DD")': dayjs('2021-02-28').isoWeekday(1).format('YYYY-MM-DD'),
        'dayjs("2021-02-28").add(1, "week").day(1).format("YYYY-MM-DD")': dayjs('2021-02-28').add(1, 'week').day(1).format('YYYY-MM-DD'),
        'dayjs("2021-02-28").startOf("isoWeek").format("YYYY-MM-DD")': dayjs('2021-02-28').startOf('isoWeek').format('YYYY-MM-DD')
      }
    },

    nextWeekNr () {
      return dayjs().add(1, 'week').isoWeek()
    },

    currentWeeksMonday () {
      return dayjs().startOf('isoWeek').format('YYYY-MM-DD')
    },

    nextWeeksMonday () {
      return dayjs().add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    }
  },

  methods: {
    fetchWeekplans () {
      axios.get(process.env.VUE_APP_API_URI + '/weekplans')
        .then(response => {
          this.weekplans = response.data.data

          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
          this.requestError = true
        })
    },

    deleteWeekplan (data) {
      if (!confirm('Är du säker?')) {
        return
      }

      axios.delete(process.env.VUE_APP_API_URI + '/weekplans/' + data.id, {})
        .then(() => {
          this.weekplans = this.weekplans.filter(weekplan => weekplan.id !== data.id)
        })
        .catch(() => {})
    }
  },

  mounted () {
    this.fetchWeekplans()
  }
}
</script>
